import { get, isNil } from "lodash";
import { destroyCookie, parseCookies, setCookie } from "nookies";

export class Storage {
  static get domain() {
    return window.location.hostname;
  }
  static get cookiesContext() {
    return null;
  }
  static get cookiesPath() {
    return "/";
  }

  static get(key: string, defaultValue: any = null) {
    return localStorage.getItem(key) ?? defaultValue;
  }
  static set(key: string, value: any) {
    if (!isNil(value)) localStorage.setItem(key, value);
    else localStorage.removeItem(key);
  }

  static getCookies(key: string, defaultValue = null) {
    return get(parseCookies(), key, defaultValue);
  }
  static setCookies(
    key: string,
    value: any,
    path = Storage.cookiesPath,
    maxAge = 604800,
    sameSite = true
  ) {
    if (!isNil) {
      setCookie(null, key, value, {
        path,
        maxAge, // Expires after 7 days
        sameSite,
      });
    } else {
      destroyCookie(null, path);
    }
  }

  private static accessTokenKey = "zygr-accessToken";
  static get accessToken() {
    return (
      Storage.get(Storage.accessTokenKey) ??
      Storage.getCookies(Storage.accessTokenKey)
    );
  }
  static set accessToken(value: string | undefined) {
    Storage.set(Storage.accessTokenKey, value);
    Storage.setCookies(Storage.accessTokenKey, value);
  }

  private static refreshTokenKey = "zygr-refreshToken";
  static get refreshToken() {
    return (
      Storage.get(Storage.refreshTokenKey) ??
      Storage.getCookies(Storage.refreshTokenKey)
    );
  }
  static set refreshToken(value: string | undefined) {
    Storage.set(Storage.refreshTokenKey, value);
    Storage.setCookies(Storage.refreshTokenKey, value);
  }

  private static currentUserKey = "zygr-currentUser";
  static get currentUser() {
    return JSON.parse(
      Storage.get(Storage.currentUserKey) ??
        Storage.getCookies(Storage.currentUserKey)
    );
  }
  static set currentUser(value: any) {
    Storage.set(Storage.currentUserKey, JSON.stringify(value));
    Storage.setCookies(Storage.currentUserKey, JSON.stringify(value));
  }

  private static timezoneKey = "zygr-my-timezone";
  static get timezone() {
    return (
      Storage.get(Storage.timezoneKey) ??
      Storage.getCookies(Storage.timezoneKey)
    );
  }
  static set timezone(value: string | undefined) {
    Storage.set(Storage.timezoneKey, value);
    Storage.setCookies(Storage.timezoneKey, value);
  }

  private static bannerVisibilityKey = "zygr-banner-visibility";
  static get bannerVisibility() {
    return (
      (Storage.get(Storage.bannerVisibilityKey) ??
        Storage.getCookies(Storage.bannerVisibilityKey)) === "true"
    );
  }
  static set bannerVisibility(value: boolean) {
    Storage.set(Storage.bannerVisibilityKey, value);
    Storage.setCookies(Storage.bannerVisibilityKey, value);
  }

  private static bannerVersionKey = "zygr-banner-version";
  static get bannerVersion() {
    return parseInt(
      Storage.get(Storage.bannerVersionKey) ??
        Storage.getCookies(Storage.bannerVersionKey)
    );
  }
  static set bannerVersion(value: number) {
    Storage.set(Storage.bannerVersionKey, value);
    Storage.setCookies(Storage.bannerVersionKey, value);
  }

  private static acceptCookieKey = "zygr-accept-cookie";
  static get acceptCookie() {
    return Storage.get(Storage.acceptCookieKey);
  }
  static set acceptCookie(value: boolean | undefined) {
    Storage.set(Storage.acceptCookieKey, value);
  }

  private static splashKey = "zygr-splash";
  static get splash() {
    return Storage.get(Storage.splashKey);
  }
  static set splash(value: boolean | undefined) {
    Storage.set(Storage.splashKey, value);
  }

  private static companyPasscodeKey = "zygr-company-passcode";
  static get companyPasscode() {
    return Storage.get(Storage.companyPasscodeKey, false);
  }
  static set companyPasscode(value: boolean | undefined) {
    Storage.set(Storage.companyPasscodeKey, value);
  }

  private static isRunKey = "isRun";
  static get isRuncode() {
    return Storage.get(Storage.isRunKey);
  }
  static set isRuncode(value: boolean | undefined) {
    Storage.set(Storage.isRunKey, value);
  }
  private static RoleKey = "rolecode";
  static get Rolecode() {
    return Storage.get(Storage.RoleKey);
  }
  static set Rolecode(value: string | undefined) {
    Storage.set(Storage.RoleKey, value);
  }

  private static homeTutorialsKey = "zygr-home-tutorial-index";
  static get homeTutorialIndex(): number {
    const result = parseInt(Storage.get(Storage.homeTutorialsKey, "0"));
    return result;
  }
  static set homeTutorialIndex(value: number) {
    Storage.set(Storage.homeTutorialsKey, value);
  }

  private static profileTutorialsKey = "zygr-profile-tutorial-index";
  static get profileTutorialIndex(): number {
    const result = parseInt(Storage.get(Storage.profileTutorialsKey, "0"));
    return result;
  }
  static set profileTutorialIndex(value: number) {
    Storage.set(Storage.profileTutorialsKey, value);
  }
}
