import { Api } from "@/lib/network";
import {
  ADD_CONTACT_US,
  GET_BLOG,
  GET_BUDGET,
  GET_CHOOSE_REASON,
  GET_INDUSTRIES,
  GET_PROJECTS,
  GET_PROJECTS_TYPE,
  GET_SERVICES,
  GET_TESTIMONIALS,
} from "./queries";
import { get } from "lodash";

export class BaseModel {
  static async getTestimonials(payload: { offset: number; size?: number }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_TESTIMONIALS, payload);
    return {
      ...rest,
      results: get(data, "testimonials_list"),
    };
  }

  static async getIndustries(payload: { offset: number; size?: number }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_INDUSTRIES, payload);
    return {
      ...rest,
      results: get(data, "industriesList"),
    };
  }

  static async getServices(payload: { offset: number; size?: number }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_SERVICES, payload);
    return {
      ...rest,
      results: get(data, "services_list"),
    };
  }

  static async getChooseReason(payload: { offset: number; size?: number }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_CHOOSE_REASON, payload);
    return {
      ...rest,
      results: get(data,'choose_list'),
    };
  }

  static async getBlog(payload: { offset: number; size?: number }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_BLOG, payload);
    return {
      ...rest,
      results: get(data, "blog_list"),
    };
  }

  static async getProjects(payload: {
    offset: number;
    type_id: string;
    size?: number;
  }) {
    payload.size = 10;
    const { data, ...rest } = await Api.post(GET_PROJECTS, payload);
    return {
      ...rest,
      results: get(data, "projects_list"),
    };
  }

  static async getProjectType() {
    const { data, ...rest } = await Api.post(GET_PROJECTS_TYPE);
    return {
      ...rest,
      results: get(data, "projectstypeList"),
    };
  }
  static async getBudget() {
    const { data, ...rest } = await Api.post(GET_BUDGET);
    return {
      ...rest,
      results: get(data, "budgetList"),
    };
  }

  static async addContactUs(payload: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    describe_your_task: string;
    Project_Type: string;
    budget: string;
  }) {
    const { data, ...rest } = await Api.post(ADD_CONTACT_US, payload);
    return {
      ...rest,
      results: data,
    };
  }
}
