import React from "react";
import { useNavigate } from "react-router-dom";
export const HomeAbout: React.FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div className={className}>
      <div className="relative w-full ">
        <div className="lg:ml-[5%] content flex flex-col items-start md:w-[500px] lg:w-[786px]">
          <div className="uppercase text-[#000] font-bold text-[32px] leading-[1.2] lg:text-[64px] text-left">
            WE ARE CODING YOUR WAY TO SUCCESS
          </div>
          <div className="text-[24px] font-[600] text-[#00] mt-[24px] mb-[16px] text-left">
            Insisting on the win-win cooperation, Zhongyeguangrong Intelligence
            Technology Co., Ltd. would like to benefit the clients, the
            enterprises, the staffs and the common people.
          </div>
          <button
            className="mt-[32px] bg-[#FFB8D0] w-[200px] h-[68px] rounded-[9px] flex items-center justify-center"
            onClick={() => navigate("/about")}
          >
            <span className="text-[#00] text-[16px] font-[600] uppercase">
              Get in touch
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
