import React, { Suspense } from "react";
import "./App.css";
import { Loader, MainLayout } from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes";
function App() {
  return (
    <MainLayout>
      <Routes>
        {routes.map((ele) => (
          <Route element={<ele.element />} key={ele.name} path={ele.path}>
            {ele.children &&
              ele.children.map((subele) => (
                <Route
                  element={<subele.element />}
                  key={subele.name}
                  path={subele.path}
                ></Route>
              ))}
          </Route>
        ))}
      </Routes>
    </MainLayout>
  );
}

export default App;
