import Home from "@/pages";
import Blog from "@/pages/blog";
import BlogDetails from "@/pages/blog/details";
import Industries from "@/pages/industries";
import Projects from "@/pages/projects";
import Services from "@/pages/services";
import About from "@/pages/about";

export type RouteType = {
  children?: RouteType[];
  element: React.FC;
  hidden?: boolean;
  name: string;
  path: string;
  title: string;
};

export const navRoutes: RouteType[] = [
  {
    element: Services,
    hidden: false,
    name: "/services",
    path: "/services",
    title: "Services",
  },
  {
    element: Industries,
    hidden: false,
    name: "/industries",
    path: "/industries",
    title: "Industries",
  },
  {
    element: Projects,
    hidden: false,
    name: "/projects",
    path: "/projects",
    title: "Projects",
  },
  {
    element: Blog,
    hidden: false,
    name: "/blog",
    path: "/blog",
    title: "Blog",
  },
];

const routes: RouteType[] = [
  ...navRoutes,
  {
    element: Home,
    hidden: false,
    name: "/",
    path: "/",
    title: "Home",
  },
  {
    element: About,
    hidden: false,
    name: "/about",
    path: "/about",
    title: "About",
  },
  {
    element: BlogDetails,
    hidden: true,
    name: "/blog",
    path: "/blog/:id",
    title: "Blog Details",
  }
  
];
export default routes;
