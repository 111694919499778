import React from "react";

import clsxm from "@/lib/clsxm";

interface Props {
  className?: string;
  label?: string;
}

export const Loader: React.FC<Props> = ({
  className = "",
  label = "Loading",
}) => {
  return (
    <div
      className={clsxm(
        "flex w-full flex-col items-center justify-center py-[120px]",
        className
      )}
    >
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={clsxm("mt-[16px] text-[16px] font-[400] text-grey")}>
        {label}
      </div>
    </div>
  );
};
