import { ContactUs } from "@/components";

export default function About() {
  return (
    <div
      className="about-page relative mt-[-88px] pt-[190px] pb-[100px]"
      style={{
        background: "url(images/about/bg.png) no-repeat",
        backgroundSize: "contain",
      }}
    >
      <div className="lg:max-w-[90%] px-[36px] lg:px-0 lg:mx-auto">
        <div className="uppercase font-bold text-[40px] text-[#000] mb-[24px] text-center">
          hello,let's chat
        </div>
        <div className="text-[#000] text-[20px] mb-[126px] mx-auto text-center">
          <p>
            Have a Project in mind? Tell us everything about your project or
            product,we’ll be glad to help.
          </p>
        </div>

        <ContactUs className="mt-[112px] lg:mt-[210px]" />
      </div>
    </div>
  );
}
