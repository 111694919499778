import clsxm from "@/lib/clsxm";

export const IndustrieCard: React.FC<{ item: any,index:number }> = ({ item,index}) => {
  const icons = [];
  return (
    <div
      className={clsxm(
        "p-[28px] box-border border border-[#D0EDFD] mb-[32px] lg:mb-0"
        // "p-[28px] lg:p-[40px] box-border border rounded-[10px] lg:rounded-none lg:border-t-0 lg:border-r-0 lg:border-b border-[#D0EDFD] mb-[32px] lg:mb-0",
        // [0, 3].includes(index) ? "lg:border-l-0" : "",
        // [3, 4, 5].includes(index) ? "lg:border-b-0" : "",
        // [2, 5].includes(index) ? "lg:border-r-0" : ""
      )}
    >
      <div
        className={clsxm(
          "2xl:h-[80px] flex flex-col items-start 2xl:flex-row 2xl:items-center mb-[28px] 2xl:mb-[68px]"
        )}
      >
        <img
          // src="images/icons/thumb.svg"
          src={`images/icons/thumb_${index+1}.png`}
          className="w-[60px] h-[60px] mr-[42px]"
        />
        <div className="font-bold uppercase text-[24px] text-[#000] my-[24px]">
          {item.industries_name}
        </div>
      </div>
      <div className="text-left text-[#000] text-[20px]">
        {item.industries_content}
      </div>
    </div>
  );
};
