import { createReducer, nanoid } from "@reduxjs/toolkit";
// import { getBlogs } from "./actions";
// import { fetchBlogsList } from './actions'
import { fetchBlogsList } from './hooks'
import { get } from "lodash";

export interface IBlog {
  id: number;
  blog_name: string;
  blog_content: string;
  url: string;
}

export interface BlogState {
  total: number;
  blogs: IBlog[];
  loading: boolean;
}

const initialState:BlogState = {
  total:0,
  blogs: [],
  loading: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(fetchBlogsList.pending,(state,{payload})=>{
    state.loading =  true;
  }).addCase(fetchBlogsList.fulfilled,(state,{payload})=>{
    state.blogs = get(payload,'data.blog_list',[]);
    state.total = get(payload,'data.total',0);
    state.loading =  false;
  }).addCase(fetchBlogsList.rejected,(state,{payload})=>{
    console.log('rejected',state,payload)
    state.total = 0;
    state.blogs = [];
    state.loading =  false;
  })
});
