import React, { useCallback, useEffect, useRef, useState } from "react";
import clsxm from "@/lib/clsxm";
import { navRoutes } from "@/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { APP_EMAIL_ADDRESS, APP_PHONE_NUMBER } from "@/constants/defaultValues";
import { Backtop } from "../backtop";

export const AppHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const handleWindowScroll = useCallback(() => {
    const scrollValue = window.scrollY;
    if (scrollValue > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
    document.documentElement.style.setProperty("--scroll-y", `${scrollValue}px`);
  }, []);

  useEffect(() => {
    let rafId: number | undefined;

    const handleScroll = () => {
      if (rafId !== undefined) {
        cancelAnimationFrame(rafId);
      }
      rafId = requestAnimationFrame(handleWindowScroll);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      if (rafId !== undefined) {
        cancelAnimationFrame(rafId);
      }
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleWindowScroll]);

  return (
<>
<header
      className={clsxm(
        "mx-auto relative flex max-w-[90%] items-center justify-between w-full z-[2024] h-[88px]"
      )}
    >
      <img
        src="images/logo.svg"
        alt="logo"
        className="cursor-pointer"
        onClick={() => navigate("/")}
      />
      <img
        src="images/icons/menu.svg"
        className="w-[24px] h-[24px] lg:hidden block"
        onClick={() => setIsMobileMenuOpen(true)}
      />
      <div className="ml-auto hidden lg:flex items-center">
        <div className="menus flex">
          {navRoutes.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                navigate(item.path);
              }}
              className={clsxm(
                "h-[78px] flex items-center justify-center cursor-pointer  mx-[16px]"
              )}
            >
              <div
                className={clsxm(
                  "text-[#000000] font-[600] text-[16px] py-[10px] box-border select-none uppercase",
                  item.path === location.pathname
                    ? "border-b-[3px] border-[#6A9CFD]"
                    : ""
                )}
              >
                {item.title}
              </div>
            </div>
          ))}
        </div>

        <button
          className="ml-[48px] bg-[#FFB8D0] rounded-[9px] text-[16px] font-[600] uppercase w-[187px] h-[68px] flex items-center justify-center border-none"
          onClick={() => {
            navigate("/about");
          }}
        >
          Contact Us
        </button>
      </div>
      {isMobileMenuOpen && (
        <Menus
          onChange={() => {
            setIsMobileMenuOpen(false);
          }}
        />
      )}
    </header>

{isVisible && <Backtop />}
</>

  );
};

const Menus: React.FC<{ onChange: () => void }> = (props) => {
  const { onChange } = props;
  const navigate = useNavigate();
  const { height } = useWindowSize();
  return (
    <div>
      <div
        className="bg-[rgba(0,0,0,.4)] w-full h-full fixed inset-0 z-[2023]"
        onClick={onChange}
      ></div>
      <div
        className="w-[291px] bg-[#FCFEFF] fixed right-0 top-0 bottom-0 z-[2024]"
        style={{
          height: height + "px",
        }}
      >
        <div className="flex items-center justify-between py-[30px] px-[23px] box-border">
          <img
            src="images/logo.svg"
            alt="logo"
            className="cursor-pointer w-[101px] h-[44px]"
            onClick={() => {
              onChange();
              navigate("/");
            }}
          />
          <img
            src="images/icons/menu-fill.svg"
            className="w-[44px] h-[44px]"
            onClick={() => {
              onChange();
              navigate("/");
            }}
          />
        </div>

        <div className="menus flex flex-col items-center justify-center">
          {navRoutes.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                onChange();
                navigate(item.path);
              }}
              className={clsxm(
                "h-[78px] flex items-center justify-center cursor-pointer  mx-[16px]"
              )}
            >
              <div
                className={clsxm(
                  "text-[#000000] font-[600] text-[16px] py-[10px] box-border select-none uppercase",
                  item.path === location.pathname
                    ? "border-b-[3px] border-[#6A9CFD]"
                    : ""
                )}
              >
                {item.title}
              </div>
            </div>
          ))}
          <button
            className="bg-[#FFB8D0] rounded-[9px] mb-[24px] mt-[48px] text-[16px] font-[600] uppercase w-[187px] h-[68px] flex items-center justify-center border-none"
            onClick={() => {
              onChange();
              navigate("/about");
            }}
          >
            Contact Us
          </button>

          <div className="flex flex-col items-center justify-center">
            <span className="flex items-center mb-[18px]">
              <img
                src="images/icons/phone-fill.svg"
                className="w-[24px] h-[24px] mr-[16px]"
              />
              <span className="text-[20px] text-[#6A9CFD]">
                {APP_PHONE_NUMBER}
              </span>
            </span>
            <span className="flex items-center">
              <img
                src="images/icons/email-fill.svg"
                className="w-[24px] h-[24px] mr-[16px]"
              />
              <span className="text-[20px] text-[#6A9CFD]">
                {APP_EMAIL_ADDRESS}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
