import { BaseModel } from "@/core";
import clsxm from "@/lib/clsxm";
import React, { useEffect } from "react";

export const WhyPeopleChooseUs: React.FC<{ className?: string }> = ({
  className,
}) => {
  const [chooseReasons, setChooseReasons] = React.useState<any[]>([]);
  useEffect(()=>{
    getChooseReason()
  },[])

  const getChooseReason = async () => {
    try {
      const payload= {
        offset:1
      }
      const {results} = await BaseModel.getChooseReason(payload);
      if(results.length > 0) {
        setChooseReasons(results);
      }
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[68px]"
        )}
      >
        Why people choose us?
      </div>
     {chooseReasons.length > 0 &&  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-[10px] gap-y-[32px] md:gap-[32px]">
        {chooseReasons.map((item, index) => {
          return (
            <div key={index} className={clsxm("box-border")}>
              <div
                className={clsxm(
                  "flex items-start flex-col mb-[32px] lg:mb-[68px]"
                )}
              >
                <img
                  src={item.url || 'images/icons/thumb.svg'}
                  className="w-[60px] h-[60px] mb-[24px] lg:mb-[42px]" 
                  onError={(e)=>{
                    (e.target as HTMLImageElement).src = "images/icons/thumb.svg" 
                  }}
                />
                <span className="font-bold uppercase text-[24px] text-[#000]">
                  {item.choose_season}
                </span>
              </div>
              <div className="text-left text-[#000] text-[20px]">
               {item.choose_content}
              </div>
            </div>
          );
        })}
      </div>
      }
    </div>
  );
};
