import { useEffect, useState } from "react";
import clsxm from "@/lib/clsxm";
import { useAsync, useWindowSize } from "react-use";
import { BaseModel } from "@/core";
import { useNavigate } from "react-router-dom";
import { Loader, TagSlider } from "@/components";
export default function Projects() {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const [projectTypeList, setProjectTypeList] = useState<
    { id: number; projects_type: string;type_id:string }[]
  >([]);
  const [projectList, setProjectList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [curIndex, setCurIndex] = useState(0);

  useAsync(async () => {
    await getProjectType();
    await getProjects('');
  }, []);
  const getProjectType = async () => {
    try {
      const { results } = await BaseModel.getProjectType();

      setProjectTypeList(results);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async (type_id: string) => {
    try {
      const payload = {
        offset: 1,
        type_id,
      };
      setLoading(true);
      const { results } = await BaseModel.getProjects(payload);
      if (results) {
        setProjectList(results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="projects-page relative mt-[-88px] pt-[135px] lg:pt-[264px] pb-[100px]"
    >

      <img src="images/common/header-background.png" className="absolute w-full top-0 hidden md:block z-[-1]" />
      <img src="images/common/mobile-header-background.svg" className="absolute w-full top-0 block md:hidden z-[-1]" />

      <div className="relative w-full mx-auto lg:max-w-[90%] lg:px-0 px-[36px] box-border">
        <div className="w-full">
          <div className="flex flex-col items-start md:w-[40%] lg:w-[50%] mb-[50px] lg:mb-[144px]">
            <div className="uppercase text-[#000] font-bold text-[32px] leading-[1.2] lg:text-[64px] text-left">
              What are we proud of?
            </div>
            <div className="desc text-left text-[16px] font-[600] text-[#00] mt-[24px]">
              Have a Project in mind? Tell us everything about your project or
              product, we’ll be glad to help.
            </div>
            <button
              className="mt-[32px] bg-[#F24B93] w-[200px] h-[68px] rounded-[9px] flex items-center justify-center"
              onClick={() => navigate("/about")}
            >
              <span className="text-[#fff] text-[16px] font-bold uppercase">
                Get in touch
              </span>
            </button>
          </div>
          <div className="w-full md:block hidden mb-[32px]">
            <TagSlider
              slidesToShow={width < 990 ?3:(width < 1440 ? 4 : 6)}
              slidesToScroll={1}
              arrows={true}
              draggable={true}
              dots={false}
              infinite={false}
              adaptiveHeight={true}
            >

              {projectTypeList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={clsxm(
                      "!flex items-center h-[143px] md:h-[240px] md:max-h-[240px] cursor-pointer justify-center text-center rounded-[10px] p-[10px] box-border select-none text-[16px] ",
                      curIndex === index
                        ? "bg-[#6A9CFD] text-[#fff] font-[700]"
                        : "bg-[#D0EDFD] text-[#000] font-[600]"
                    )}
                    onClick={() => {
                      if (curIndex === index) return;
                      setCurIndex(index);
                      getProjects(item.type_id);
                    }}
                  >
                      {item.projects_type}
                  </div>
                );
              })}
            </TagSlider>
          </div>

            

          <div className="md:hidden grid grid-cols-2 gap-[24px] mb-[32px]">
            {projectTypeList.map((item, index) => {
              return (
                <div
                  key={index}
                  className={clsxm(
                    "h-[143px] lg:h-[240px] !flex items-center cursor-pointer justify-center text-center rounded-[10px] p-[10px] box-border select-none",
                    curIndex === index
                      ? "bg-[#6A9CFD] text-[#fff]"
                      : "bg-[#D0EDFD] text-[#000]"
                  )}
                  onClick={() => {
                    if (curIndex === index) return;
                    setCurIndex(index);
                    getProjects(item.type_id);
                  }}
                >
                  <span className="text-[16px] font-[600]">
                    {item.projects_type}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="w-full">
            {loading && <Loader />}
            {projectList.length > 0 ? (
              <div className="space-y-[72px] md:space-y-[175px]">
                {projectList.map((item, index) => (
                  <div
                    key={index}
                    className={clsxm(
                      "flex flex-col items-center md:gap-[32px] justify-around w-full",
                      index % 2 !== 0 ? "md:flex-row-reverse" : "md:flex-row"
                    )}
                  >
                    <div
                      className="w-full md:w-[440px] md:min-w-[440px] md:h-[440px] h-[304px] rounded-2xl border-solid border-l-[#d0edfd] bg-[#D0EDFD]"
                      style={{ boxShadow: "3px 4px 26px 3px #eff3fa" }}
                    >
                      <img src={item.url} className="w-full h-full rounded-2xl" />
                    </div>
                    <div className="flex flex-col items-start gap-y-[24px] lg:gap-y-[32px]">
                      <div className="text-[#000] text-[24px] uppercase font-[600] mt-[32px] mb-[24px]">
                        {item.projects_name}
                      </div>
                      <div className="flex flex-row md:flex-nowrap md:gap-0 flex-wrap justify-between gap-[24px] w-full items-center md:gap-x-[30px] lg:gap-x-[65px]">
                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            INDUSTRY
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {item.industry}
                          </span>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Project Type
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {item.projects_type}
                          </span>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Duration
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {item.duration}
                          </span>
                        </div>
                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Budget
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {item.budget}
                          </span>
                        </div>
                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Team Size
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {item.team_size}
                          </span>
                        </div>
                      </div>
                      <div className="text-[#000] text-left text-[14px] md:text-[20px]">
                        {item.projects_content}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) :  <div className="no-data text-[#000] py-[60px] text-center uppercase text-[30px]">
            no data
          </div>}

          </div>
        </div>
      </div>
    </div>
  );
}
