import { IBlog } from "@/store/blog/reducer";
import React from "react";

export const PostCard: React.FC<{ item: IBlog }> = ({ item }) => {
  return (
    <div
      className=" rounded-[10px]"
      style={{
        boxShadow: "0px 4px 34px 0px #E2E0E05E",
      }}
    >
      <div className="w-full h-[304px] lg:h-[455px] bg-[#D9D9D9] rounded-tl-[10px] rounded-tr-[10px]">
        <img
          src={item.url}
          className="h-full w-full rounded-tl-[10px] rounded-tr-[10px]"
        />
      </div>
      <div className="md:px-[24px] border flex flex-col border-t-0 border-[#D0EDFD] lg:py-[40px] p-[16px] lg:px-[31px] box-border bg-white rounded-bl-[10px] rounded-br-[10px]">
        <div className="text-[#000] text-[16px] lg:text-[20px] font-bold text-left">
          {item.blog_name}
        </div>
        <p className="text-[#000] text-[14px] lg:text-[16px] flex-1 my-[24px] text-left line-clamp-3">
          {item.blog_content}
        </p>
        <button
          className="flex w-full mt-auto h-[54px] rounded-[34px] items-center justify-end pr-[20px] box-border"
          style={{
            background:
              "linear-gradient(270deg, #FFB8D0 2.67%, rgba(255, 255, 255, 0) 96%)",
          }}
        >
          <span className="text-[#000] text-[16px] font-[600] capitalize">
            Read Full
          </span>
          <img src="images/icons/line-more.svg" className="ml-[14px]" />
        </button>
      </div>
    </div>
  );
};
