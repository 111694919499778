import { ContactUs, Loader, PostCard, Working } from "@/components";
import { useEffect, useState } from "react";
import { BaseModel } from "@/core";

export default function Industries() {
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getIndustries();
  }, []);

  const getIndustries = async () => {
    setLoading(true);
    try {
      const payload = { offset: 1 };
      const { results } = await BaseModel.getIndustries(payload);
      if (results) {
        setIndustries(results);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className="industries-page relative mt-[-88px] pt-[135px] lg:pt-[332px] pb-[100px]"
    >

<img src="images/industries/header-background.png" className="absolute w-full top-0 z-[-1]" />

      <div className="lg:max-w-[80%] lg:mx-auto w-full">
        <div className="px-[36px] lg:px-0">
          <div className="text-[#000] font-bold text-[32px] lg:text-[40px] uppercase text-center">
            INDUSTRIES
          </div>
          <p className="text-[#000] text-[16px] lg:text-[24px] font-[600] text-center mt-[24px] mb-[94px] lg:mb-[200px]">
            We guarantee you high-level, standard-compliant custom software
            tailored to your industry needs.
          </p>

          {loading ? <Loader /> : industries.length > 0 && <Working industries={industries} />}
        </div>

        <ContactUs className="mt-[163px] lg:mt-[206px] px-[36px] lg:px-0" />
      </div>
    </div>
  );
}
