import { Storage } from "@/utils";
import axios, { AxiosRequestConfig } from "axios";
import { get, isEmpty, isNil } from "lodash";

const environment = process.env.REACT_PUBLIC_ENV as string;

export const endpoint =
  environment === "production"
    ? process.env.REACT_PUBLIC_ENV_PUBLIC_PROD_API
    : environment === "staging"
      ? process.env.REACT_PUBLIC_ENV_PUBLIC_STAGE_API
      : environment === "local"
        ? process.env.REACT_PUBLIC_ENV_PUBLIC_LOCAL_API
        : process.env.REACT_PUBLIC_ENV_PUBLIC_DEV_API;

console.log('process.env',process.env)

const BASE_URL = "https://api.zhongyeguangrong.com";
interface ParsedReponseType {
  success?: boolean;
  error?: string | null;
  errorCode?: number | null;
  data?: any;
  [key: string]: any;
}

export class Api {
  private static get headers() {
    const accessToken = "";
    const headers = accessToken
      ? { authorization: `Bearer ${accessToken}` }
      : undefined;
    return headers;
  }

  static createConfiguration() {
    return { ...axios.defaults, headers: Api.headers };
  }

  static parseError(error: any): ParsedReponseType {
    return {
      error: get(error, "response.errors[0].message", null),
      errorCode: get(
        error,
        "response.errors[0].extensions.response.statusCode",
        null
      ),
    };
  }

  static async refreshAccess() {
    const refreshToken = "";
    if (!refreshToken) return { success: false };
    try {
      const { success, data } = Api.handleResponse(
        await axios.post(BASE_URL + "/auth/refresh-access", { refreshToken })
      );
      if (success) {
        Storage.accessToken = data.accessToken;
        Storage.refreshToken = data.refreshToken;
      }
      return { success };
    } catch (error) {
      return { success: false };
    }
  }

  static formatError(value: string, errorCode: number) {
    if (isNil(value)) return null;

    let message = value.replace("Error: ", "");
    if (message.includes("jwt malformed")) {
      message = "Your credentials were not registered in system.";
    }
    if (message.includes("jwt expired") || errorCode === 401) {
      message =
        "The session has expired. Please refresh the page and login to your account to return to the dashboard.";
    }
    if (message.slice(-1) !== ".") {
      return message + ".";
    }
    return message;
  }

  static handleResponse(response: any): ParsedReponseType {
    try {
      if (response.data.error) {
        const { error } = response.data;
        const { code: errorCode, message = "", errors = [] } = error || {};
        if (!isNil(message)) {
          return {
            success: false,
            error: Api.formatError(message, errorCode),
            errorCode,
          };
        }

        if (!isEmpty(errors)) {
          const { msg } = errors[0];
          return {
            success: false,
            error: Api.formatError(msg, errorCode),
            errorCode,
          };
        }
      } else if (response.data.errors) {
        const { errors } = response.data;
        const message = get(errors[0], "message", null);
        const errorCode = get(errors[0], "extensions.exception.status", 0);
        return {
          error: message,
          errorCode,
        };
      } else if (response.data.code !== 0) {
        return {
          success: false,
          error: response.data.msg,
          errorCode: response.data.code,
        };
      } else {
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
    return { success: true, data: response.data };
  }

  static async get(path: string, params: any = {}): Promise<ParsedReponseType> {
    let query = "";
    if (params) {
      query = "?";
      const keys = Object.keys(params);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        query += `${key}=${encodeURIComponent(params[key])}`;
        if (i < keys.length - 1) {
          query += "&";
        }
      }
    }

    try {
      const requestPath = BASE_URL + path + query;
      const response = await axios.get(
        requestPath,
        Api.createConfiguration() as AxiosRequestConfig
      );
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.get(path, params);
        }
      }

      throw error;
    }
  }

  static async post(
    path: string,
    params: any = {}
  ): Promise<ParsedReponseType> {
    try {
      const response = await axios.post(
        BASE_URL + path,
        params,
        Api.createConfiguration() as AxiosRequestConfig
      );
      return Api.handleResponse(response);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        const { success } = await Api.refreshAccess();
        if (success) {
          return await Api.post(path, params);
        }
      }

      return Api.parseError(error);
    }
  }
}
