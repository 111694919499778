import { APP_EMAIL_ADDRESS, APP_PHONE_NUMBER } from "@/constants/defaultValues";
import { BaseModel } from "@/core";
import clsxm from "@/lib/clsxm";
import { useEffect, useState } from "react";
import { Loader } from "../loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const ContactUs: React.FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  const [budgetList, setBudgetList] = useState<{ budget: string }[]>([]);
  const [projectTypeList, setProjectTypeList] = useState<
    { id: number; projects_type: string }[]
  >([]);

  const [budgetIndex, setBudgetIndex] = useState(0);
  const [projectTypeindex, setProjectTypeIndex] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [desc, setDesc] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProjectType();
    getBudget();
  }, []);

  const getProjectType = async () => {
    try {
      const { results } = await BaseModel.getProjectType();
      setProjectTypeList(results);
    } catch (error) {
      console.log(error);
    }
  };

  const getBudget = async () => {
    try {
      const { results } = await BaseModel.getBudget();
      setBudgetList(results);
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (value: string) => {
    let error = '';
    if (!value) {
      error = 'Please enter your email address.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Invalid email address.';
    }
    return error;
  };

  const verifyForm = () => {
    if (!firstName.trim()) {
      return "Please enter your first name";
    }
    if (!lastName.trim()) {
      return "Please enter your last name";
    }
    if (!email.trim()) {
      return "Please enter your email";
    }
    const emailState = validateEmail(email);
    if (emailState) {
      return emailState;
    }
    if (!phone.trim()) {
      return "Please enter your phone number";
    }
    if (!desc.trim()) {
      return "Please enter your description";
    }
    return true;
  };

  const initForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setDesc('');
    setProjectTypeIndex(0);
    setBudgetIndex(0);
  }


  const addContactUs = async () => {
    if (typeof verifyForm() === "string") {
      toast.error(verifyForm());
      return;
    }
    try {
      const payload = {
        budget: budgetList[budgetIndex].budget,
        Project_Type: projectTypeList[projectTypeindex].projects_type,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        describe_your_task: desc,
      };
      setLoading(true);
      await BaseModel.addContactUs(payload);
      initForm();
      toast.success("Your message has been sent");
      // console.log(result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={clsxm("box-border flex flex-col justify-center", className)}
    >
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase"
        )}
      >
        Contact Us
      </div>
      <div className="text-[#000] text-[24px] font-[600] my-[40px] text-center">
        Have a Project in mind?
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center lg:space-x-[46px]">
        <span className="flex items-center mb-[16px] lg:mb-0">
          <img
            src="images/icons/phone-fill.svg"
            className="w-[24px] h-[24px] mr-[16px]"
          />
          <span className="text-[20px] text-[#6A9CFD]">{APP_PHONE_NUMBER}</span>
        </span>
        <span className="flex items-center">
          <img
            src="images/icons/email-fill.svg"
            className="w-[24px] h-[24px] mr-[16px]"
          />
          <span className="text-[20px] text-[#6A9CFD]">
            {APP_EMAIL_ADDRESS}
          </span>
        </span>
      </div>

      <div className="form mt-[39px] lg:mt-[90px] mb-[90px] flex-col">
        <div className="flex flex-col lg:flex-row mx-auto justify-center relative">
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[24px] md:gap-[24px] lg:mr-[62px] lg:w-[512px] lg:min-w-[512px]"> */}
          <div className="md:min-w-[512px] md:mr-[62px] w-full">
            <div className="grid md:grid-cols-2 w-full gap-y-[24px] md:gap-[24px]">
              <div className="">
                <div className="mb-[16px] text-[16px] text-[#000] text-left">
                  First name
                </div>
                <div className="border-[2px] border-[#6A9CFD] w-full h-[48px] rounded-[46px] flex items-center px-[16px] box-border">
                  <input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    placeholder="First Name"
                    className="w-full outline-none border-none bg-transparent"
                  />
                </div>
              </div>
              <div className="">
                <div className="mb-[16px] text-[16px] text-[#000] text-left">
                  Last name
                </div>
                <div className="border-[2px]  border-[#6A9CFD] w-full h-[48px] rounded-[46px] flex items-center px-[16px] box-border">
                  <input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    placeholder="Last name"
                    className="w-full outline-none border-none bg-transparent"
                  />
                </div>
              </div>
              <div className="">
                <div className="mb-[16px] text-[16px] text-[#000] text-left">
                  Email
                </div>
                <div className="border-[2px]  border-[#6A9CFD] w-full h-[48px] rounded-[46px] flex items-center px-[16px] box-border">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email"
                    className="w-full outline-none border-none bg-transparent"
                  />
                </div>
              </div>
              <div className="">
                <div className="mb-[16px] text-[16px] text-[#000] text-left">
                  Phone
                </div>
                <div className="border-[2px] border-[#6A9CFD] w-full h-[48px] rounded-[46px] flex items-center px-[16px] box-border">
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="Phone"
                    className="w-full outline-none border-none bg-transparent"
                  />
                </div>
              </div>

              <div className="md:col-span-2">
                <div className="mb-[16px] text-[16px] text-[#000] text-left">
                  Describe Your Task
                </div>
                <div className="border-[2px] border-[#6A9CFD] w-full h-[128px] rounded-[18px] flex items-center p-[16px] box-border">
                  <textarea
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    maxLength={2000}
                    placeholder="Write your message"
                    className="w-full outline-none border-none h-full bg-transparent"
                  />
                </div>
                <p className="text-[#6A9CFD] text-[14px] font-[400] mt-[16px] text-right">
                  {desc.length}/2000
                </p>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col items-start">
              <div className="mb-[16px] text-[16px] font-[600] text-[#000]">
                Project Type
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-[16px] md:gap-[24px] w-full">
                {projectTypeList.length > 0 && [...projectTypeList].splice(1).map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setProjectTypeIndex(index)}
                    className={clsxm(
                      "border-[2px] cursor-pointer select-none border-[#6A9CFD] text-[14px] lg:text-[16px] rounded-[34px] w-full text-center h-[62px] box-border flex items-center justify-center uppercase",
                      projectTypeindex === index
                        ? "bg-[#6A9CFD] text-[#fff]"
                        : ""
                    )}
                  >
                    {item.projects_type}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-[35px] flex flex-col items-start">
              <div className="mb-[16px] text-[16px] font-[600] text-[#000]">
                BUDGET
              </div>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-[16px] lg:gap-[32px] w-full">
                {budgetList.length > 0 && budgetList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setBudgetIndex(index)}
                    className={clsxm(
                      "border-[2px] cursor-pointer border-[#6A9CFD] text-[14px] lg:text-[16px] rounded-[34px] w-full h-[62px] flex items-center justify-center uppercase",
                      budgetIndex === index ? "bg-[#6A9CFD] text-[#fff]" : ""
                    )}
                  >
                    {item.budget}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-[34px] md:mt-[84px] flex justify-end space-x-[32px]">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {/* <button
                    className="text-[16px] text-[#000] uppercase bg-white border-[3px] border-[#FFB8D0] rounded-[9px] w-[195px] h-[68px]"
                    onClick={() => navigate("/about")}
                  >
                    Book A call
                  </button> */}
                  <button
                    className="text-[16px] text-[#000] uppercase bg-[#FFB8D0] font-[600] rounded-[9px] flex px-[24px] box-border items-center justify-center h-[68px]"
                    onClick={addContactUs}
                  >
                    send email to us
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
