import { Empty, Loader, PostCard } from "@/components";
import { useAppDispatch, useAppSelector } from "@/store";
import { useEffect } from "react";
import { useWindowSize } from "react-use";
import { fetchBlogsList } from "@/store/blog/hooks";

export default function Blog() {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const { total, loading, blogs } = useAppSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogsList({ offset: 1 }));
  }, []);
  return (
    <div
      className="about-page relative mt-[-88px] pt-[135px] lg:pt-[264px] pb-[100px]"
    >

      <img src="images/common/header-background.png" className="absolute w-full top-0 hidden md:block z-[-1]" />
      <img src="images/common/mobile-header-background.svg" className="absolute w-full top-0 block md:hidden z-[-1]" />

      <div className="lg:max-w-[90%] lg:mx-auto w-full px-[36px] lg:px-0">
        <div className="text-[#000] font-bold text-[32px] lg:text-[64px] mb-[98px] lg:mb-[142px] uppercase text-left lg:w-[612px]">
          What’s latest on blog
        </div>

        {loading && <Loader />}
        {blogs.length > 0 ? <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-[24px] lg:gap-[32px]">
          {blogs.map((blog, index) => (
            <PostCard key={index} item={blog} />
          ))}
        </div> : <Empty className="flex flex-col items-center justify-center pt-[50px] md:pt-[100px]"><span className="text-[#717070] text-[24px] mt-[21px]">Nothing yet</span></Empty>}
      </div>
      {/* <div className="mt-[40px] mb-[113px] lg:mt-[137px] lg:mb-[260px]">
        <div className="pagination flex items-center  justify-center">
          <img
            src="images/icons/arrow-prev.svg"
            className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px]"
          />
          <div className="pager mx-[27px] lg:mx-[56px]">
            <ul className="flex items-center space-x-[24px]">
              <li className="lg:w-[58px] lg:h-[56px] w-[47px] h-[44px] rounded-[10px] text-white flex items-center font-bold cursor-pointer justify-center bg-[#6A9CFD]">
                1
              </li>
              <li className="lg:w-[58px] lg:h-[56px] w-[47px] rounded-[10px] text-[#000] flex items-center font-bold cursor-pointer justify-center">
                2
              </li>
              <li className="lg:w-[58px] lg:h-[56px] w-[47px] rounded-[10px] text-[#000] flex items-center font-bold cursor-pointer justify-center">
                3
              </li>
            </ul>
          </div>
          <img
            src="images/icons/arrow-next.svg"
            className="w-[30px] lg:w-[50px] h-[30px] lg:h-[50px]"
          />
        </div>
      </div> */}
    </div>
  );
}
