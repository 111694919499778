export const Backtop = () => {
  const onBacktop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className="cursor-pointer select-none w-[40px] z-[999] h-[40px] bg-white fixed right-[2%] bottom-[10%] shadow-lg flex items-center justify-center"
      onClick={onBacktop}
    >
      <span className="uppercase">up</span>
    </div>
  );
};
