import { AppFooter } from "../app-footer";
import { AppHeader } from "../app-header";

interface Props {
  children: React.ReactNode;
}
export const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <AppHeader />
      {children}
      <AppFooter />
    </div>
  );
};
