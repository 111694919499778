import { CardSlider } from "@/components/core";
import { BaseModel } from "@/core";
import clsxm from "@/lib/clsxm";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
export const Testimonials: React.FC<{ className?: string }> = ({
  className,
}) => {
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const {width} = useWindowSize();
  useEffect(() => {
    getTestimonials();
  }, []);
  const getTestimonials = async () => {
    try {
      const payload = {
        offset: 1,
        type_id:'',
      };
      const { results } = await BaseModel.getProjects(payload);
      if (results.length > 0) {
        setTestimonials(results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[50px] md:mb-[68px]"
        )}
      >
        project list
      </div>

      <CardSlider arrows={width < 990 ? false : true} draggable>
        {testimonials.map((item, index) => {
          return <TestimonialCard key={index} {...item} />;
        })}
      </CardSlider>
    </div>
  );
};

const TestimonialCard = (props: any) => {
  return (
    <div className="content bg-white flex-1 flex-col flex md:p-[32px] lg:py-[150px] lg:px-[100px] box-border">
      <div className="flex flex-col items-start md:flex-row">
        <div className="md:w-[122px] md:min-w-[122px] w-full md:h-[95px] bg-[#D9D9D9] md:mr-[32px] mb-[32px] md:mb-0">
          <img className="h-full w-full" src={props.url} />
        </div>
        <div className="flex flex-col items-start w-full md:w-[35%]">
          <div className="text-[#000] text-[24px] font-bold uppercase text-left line-clamp-2 md:h-[75px]">
            {props.projects_name}
          </div>
          <div className="my-[16px] text-[16px] uppercase text-left">
            {props.position}
          </div>
          <div className="text-[#000] text-[16px] font-bold uppercase text-left mb-[24px] md:mb-0">
            {props.projects_type}
          </div>
        </div>
        <div className="text-[16px] text-[#000] space-y-[16px] md:ml-[53px] flex-1">
          <p className="text-left md:line-clamp-3">{props.projects_content}</p>
        </div>
      </div>

      {/* <div className="flex flex-col md:flex-row items-start md:items-center justify-between mt-[33px] space-y-[32px] md:space-y-0"> */}
      <div className="mt-[50px] flex flex-row md:flex-nowrap md:gap-0 flex-wrap justify-between gap-[24px] w-full items-center md:gap-x-[30px] lg:gap-x-[65px]">
      <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            INDUSTRY
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {props.industry}
                          </span>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Project Type
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {props.projects_type}
                          </span>
                        </div>

                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Duration
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {props.duration}
                          </span>
                        </div>
                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Budget
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {props.budget}
                          </span>
                        </div>
                        <div className="flex flex-col items-start">
                          <span className="text-[#000] text-[12px] lg:text-[16px] font-[600] uppercase mb-[8px] lg:mb-[24px]">
                            Team Size
                          </span>
                          <span className="text-[#000] text-[14px] lg:text-[16px] ">
                            {props.team_size}
                          </span>
                        </div>
      </div>
    </div>
  );
};
