import { ContactUs, ServiceCard, WhyPeopleChooseUs } from "@/components";
import { BaseModel } from "@/core";
import clsxm from "@/lib/clsxm";
import { useEffect, useState } from "react";
export default function Services() {
  const [services, setServices] = useState([]);
  useEffect(() => {
    getServices();
  }, []);
  const getServices = async () => {
    try {
      const payload = {
        offset: 1,
      };
      const { results } = await BaseModel.getServices(payload);
      if (results.length > 0) {
        setServices(results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        className="services-page relative mt-[-88px] pt-[135px] lg:pt-[264px] pb-[100px]"
        style={{
          background: "url(images/services/header-background.png) no-repeat",
          backgroundSize: "contain",
        }}
      >

<img src="images/services/header-background.png" className="absolute w-full top-0 z-[-1]" />
        <div className="flex justify-center">
          <div className="flex w-full flex-col items-center gap-[24px]  lg:w-[1085px]  px-[36px] box-border">
            <span className="text-[#000] text-center font-bold  uppercase text-[32px] lg:text-[40px]   font-montserrat">
              SERVICES
            </span>
            <div className="flex flex-col justify-center gap-[24px] self-stretch">
              <span className="  font-bold self-stretch text-[#000] text-[16px] lg:text-[24px]  text-center ">
                We Have A Team Of Experienced Project Managers Who Are Able To
                Work With Complex Projects And Act As Crisis Managers If Any
                Issue Occurred.
              </span>
            </div>
          </div>
        </div>
        <div className="lg:max-w-[90%] lg:mx-auto px-[36px] lg:px-0">
          <ServiceCard
            className="mt-[79px] lg:mt-[266px]"
            services={services}
          />

          <WhyPeopleChooseUs className="mt-[112px] lg:mt-[167px]" />

          <ContactUs className="mt-[112px] lg:mt-[167px]" />
        </div>
      </div>
    </>
  );
}
