import { CarouselSlider } from "@/components/core";
import clsxm from "@/lib/clsxm";
import React from "react";
export const HomeTeam: React.FC<{ className?: string }> = ({ className }) => {

  const list =['https://niuerlicai.oss-cn-guangzhou.aliyuncs.com/20230606143402.jpg',
  'https://niuerlicai.oss-cn-guangzhou.aliyuncs.com/20230608174940.jpg']

  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-left lg:text-center uppercase"
        )}
      >
        Meet our team
      </div>
      <div
        className={clsxm(
          "mt-[24px] lg:mt-[40px] mb-[16px] text-[24px] font-[600] text-left lg:text-center"
        )}
      >
        Zhongyeguangrong Intelligence Technology Co., Ltd.
      </div>
      <div className="text-[20px] text-left lg:text-center">
        Currently Zhongyeguangrong owns a 1200-square-meter office space and 50
        staffs. It is an Internet software technology company that not only
        takes finance and blockchain as its core but also values technology,
        product quality, talented people and customer service.
      </div>

      {/* h-[140px] lg:h-[466px] */}
      <div className="w-full bg-[#D9D9D9] mt-[31px] lg:mt-[113px]">
      <CarouselSlider draggable>
          {list.map((img, index) => (
            <img key={index} src={img} alt="img" className="w-full h-full" />
          ))}
      </CarouselSlider>
      </div>
    </div>
  );
};
