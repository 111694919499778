import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import blogs from "./blog/reducer";

const PERSISTED_KEYS: string[] = [];

const store = configureStore({
  reducer: {
    blogs,
  },
  // middleware:(getDefaultMiddleware)=>[...getDefaultMiddleware(), save({states: PERSISTED_KEYS, debounce: 1000})],
  // preloadedState: load({states: PERSISTED_KEYS}),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
