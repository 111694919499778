import { useSelector, useDispatch } from "react-redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "@/lib/network";
import { GET_BLOG } from "@/core/queries";

export interface PayloadInterface {
    offset: number; 
    size?: number
}

export interface BlogInterface {
    id:number;
    blog_name:string;
    blog_content:string;
    url:string;
}

export interface ApiResponse<T = any> {
    code:number;
    msg:string;
    data?:T
}


export const fetchBlogsList = createAsyncThunk('getBlog',async(payload:PayloadInterface)=>{
    return Api.post(GET_BLOG, payload)
})