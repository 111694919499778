import clsxm from "@/lib/clsxm";
import React from "react";

export const ServiceCard: React.FC<{ className?: string; services: any[] }> = ({
  className,
  services,
}) => {
  return (
    <div className={clsxm(className)}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[68px]"
        )}
      >
        What we offer
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-[32px] md:gap-[32px] mt-[30px] lg:mt-0">
        {services.length > 0 && services.map((item, index) => (
          <div
            key={index}
            className="px-[29px] py-[32px] box-border border border-[#D0EDFD] shadow-sm rounded-[10px]"
          >
            <div className="text-[#000] text-[24px] font-[600] mb-[40px] uppercase">
              {item.services_name}
            </div>
            <div className="text-[#000] text-[16px] mb-[16px] text-left">
              Our expertise:
            </div>
            <div className="flex flex-col pl-[48px] box-border">
              {item.expertise &&
                item.expertise.length > 0 &&
                item.expertise.map((vo: string, idx: number) => {
                  return (
                    <div key={idx} className="flex mb-[24px]">
                      <div className="w-[18px] min-w-[18px] h-[18px] rounded-full bg-[#AEE4FF] mr-[24px] relative top-[5px]"></div>
                      <span className="text-[#000] text-[16px] flex-1">{vo}</span>
                    </div>
                  );
                })}
              {/* <div className="flex items-center">
                <div className="w-[18px] h-[18px] rounded-full bg-[#AEE4FF] mr-[24px]"></div>
                <span className="text-[#000] text-[16px]">voluptatem</span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
