import {
  APP_EMAIL_ADDRESS,
  APP_LOCATION,
  APP_PHONE_NUMBER,
  LINKEDIN_ADDRESS,
  TWITTER_ADDRESS,
} from "@/constants/defaultValues";
import clsxm from "@/lib/clsxm";
import { navRoutes } from "@/routes";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
export const AppFooter = () => {
  const navigate = useNavigate();
  const {width} = useWindowSize();


  const backgroundImage = useMemo(()=>{
    return width > 768 ? "/images/desktop-footer-background.png" : "/images/mobile-footer-background.svg"
  },[width])

  return (
    <div className="relative md:flex w-full h-full" style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}>
      <div
        className={clsxm(
          "flex flex-col md:flex-row md:w-[90%] md:justify-between md:mx-auto items-center relative py-[36px] box-border"
        )}
      >
        <div className="flex flex-col">
          <img
            src="images/logo.svg"
            alt="logo"
            className="w-[211px] h-[60px]"
          />
          <div className="flex items-center justify-between mt-[29px] md:space-x-[64px] md:mt-[66px]">
            <a href={TWITTER_ADDRESS} target="_blank">
              <img
                src="images/icons/twitter.svg"
                alt="twitter"
                className="w-[50px] h-[44px]"
              />
            </a>
            <a href={LINKEDIN_ADDRESS} target="_blank">
              <img
                src="images/icons/linkedin.svg"
                alt="linkedin"
                className="w-[50px] h-[50px]"
              />
            </a>
          </div>
        </div>
        <div className="links flex flex-col mt-[63px] md:flex-row md:space-x-[180px]">
          <div className="flex flex-col">
            <div className="text-[20px] text-left font-bold text-white border-b-[1px] border-[#3977E7] uppercase pb-[12px] box-border">
              Navigation
            </div>
            {navRoutes.map((route, index) => (
              <div
                key={index}
                className="text-[16px] font-[600] text-white h-[68px] flex items-center box-border cursor-pointer"
                onClick={() => {
                  navigate(route.path);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {route.title}
              </div>
            ))}
          </div>
          <div className="flex flex-col">
            <div className="text-[20px] font-bold text-left text-white border-b-[1px] border-[#3977E7] pb-[12px] box-border">
              Contact Info
            </div>
            <div className="text-[16px] font-[600] text-white h-[68px] flex items-center pl-[16px] box-border">
              <img
                className="w-[24px] h-[24px] mr-[16px]"
                src="images/icons/phone.svg"
              />
              <span className="text-white font-[500] text-[20px]">
                {APP_PHONE_NUMBER}
              </span>
            </div>
            <div className="text-[16px] font-[600] text-white h-[68px] flex items-center pl-[16px] box-border">
              <img
                className="w-[24px] h-[24px] mr-[16px]"
                src="images/icons/email.svg"
              />
              <span className="text-white font-[500] text-[20px]">
                {APP_EMAIL_ADDRESS}
              </span>
            </div>
            <div className="text-[16px] font-[600] text-white h-[68px] flex items-center pl-[16px] box-border">
              <img
                className="w-[24px] h-[24px] mr-[16px]"
                src="images/icons/location.svg"
              />
              <span className="text-white font-[500] text-[20px]">
                {APP_LOCATION}
              </span>
            </div>
          </div>
        </div>

        <div className="text-center md:text-left static md:absolute left-0 mt-[20%] md:bottom-[17px] text-white text-[16px] font-bold">
          © 2023 - 2024 All rights reserved. ZY GUANGRONG.
        </div>
      </div>
    </div>
  );
};
