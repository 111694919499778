import clsxm from "@/lib/clsxm";
import React from "react";
export const WhyChooseUs: React.FC<{ className?: string }> = ({
  className,
}) => {

  const list = [
    {id:1,content:'We are the youngest sun in this society, we have vitality, energy, ability to fight, and courage!'},
    {id:2,content:'We have a broader perspective and an understanding of various industries in this society, with rich industry experience and the ability to provide solutions'},
    {id:3,content:'We have strong learning abilities and are good at enriching ourselves with knowledge. In the rapidly developing technology industry, we constantly move forward and never fall behind'},
    {id:4,content:'We are good at accepting and embracing, and kindness and care are always our beliefs'},
  ]

  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[68px]"
        )}
      >
        Why companies choose uS?
      </div>
      <div className="bg-gradient-to-r justify-between relative from-[#fff] md:pl-[60px] md:pt-[30px] box-border to-[rgba(255,255,255,0)] rounded-[10px] flex flex-col md:flex-row items-center">
        <img
          src="images/home/thumb2.svg"
          className="left-[-12%] top-[-14%] absolute z-[-1] hidden lg:block"
        />

        <div className="flex flex-col w-full lg:w-[610px] box-border">
          {list.map((item, index) => (
            <div
              key={index}
              className="border-l-[4px] border-[#AEE4FF] text-[14px] pl-[28px] lg:pl-[32px] text-left box-border lg:text-[20px] text-[#000] mb-[40px]"
            >
              {item.content}
            </div>
          ))}
        </div>
        <div>
          <img src="images/home/thumb1.svg" />
        </div>
      </div>
    </div>
  );
};
