import clsxm from "@/lib/clsxm";
import React, { useState } from "react";

export const OurProducats: React.FC<{ className?: string }> = ({
  className,
}) => {
  const [products] = useState(["camera", "software", "codelify", "financely"]);

  const [data] = useState([
    { value: '500+', name: "PROJECTS FULFILLED",list:["PROJECTS","FULFILLED"] },
    { value: '200', name: "MULTINATIONAL CLIENTS RECOMMEND US",list:["MULTINATIONAL","CLIENTS","RECOMMEND US"] },
    { value: '90%', name: "TOP RATED",list:["TOP RATED"] },
    { value: '20', name: "EXPERIENCE IN THE SOFTWARE DEVELOPMENT INDUSTRY",list:["EXPERIENCE","IN THE","SOFTWARE","DEVELOPMENT","INDUSTRY"] },
    { value: '10', name: "TECHNICAL SERVICE EXPERIENCE",list:["TECHNICAL","SERVICE","EXPERIENCE" ]},
    { value: '5', name: "PRODUCT DEVELOPMENT EXPERIENCE",list:["PRODUCT","DEVELOPMENT","EXPERIENCE"] },
  ]);
  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase md:mb-[30px] lg:mb-[68px]"
        )}
      >
        Our products
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center w-full space-y-[16px] lg:space-y-0 md:space-x-[50px] lg:space-x-[80px]">
        {products.map((item) => {
          return (
            <div key={item} className="flex items-center h-[128px]">
              <img src={`images/icons/${item}.svg`} className="lg:mr-[15px]" />
            </div>
          );
        })}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-[61px] md:gap-[32px] mt-[30px] lg:mt-[162px]">
        {data.map((item, index) => {
          return (
            <div key={index} className=" flex flex-col">
              <span className="text-[#9FDEFD] text-[100px] 2xl:text-[124px] font-bold text-center lg:text-left">
                {item.value}
              </span>
              <div className={clsxm('flex items-center flex-col text-[24px] relative','lg:top-[-30%] lg:left-[10%]','xl:left-0')}>
                {item.list.map((item,index)=>{
                  return <span className="font-[600] text-inherit" key={index}>{item}</span>
                })
              }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
