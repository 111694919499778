import { PostCard } from '@/components';
import { useAppSelector } from '@/store';
import React from 'react';
import { useWindowSize } from 'react-use';

export default function Details() {
  const { width } = useWindowSize();
  const { total, loading, blogs } = useAppSelector((state) => state.blogs);
  return (
    <div
    className="about-page relative mt-[-88px] pt-[135px] lg:pt-[264px] pb-[100px]"
    style={{
      background:
        width < 990
          ? "url(images/common/mobile-header-background.svg)  no-repeat"
          : "url(images/common/header-background.png) no-repeat",
      backgroundSize: "cover",
    }}
  >
<div className='md:max-w-[90%] lg:max-w-[1008px] md:mx-auto'>
<div className="w-full h-[202px] md:h-[541px] bg-[#D9D9D9]">
        <img />
      </div>
    <div className='main py-[34px] px-[36px] md:px-0 box-border'>
    
      <div className='font-bold text-[22px] leading-[1.5]'>5 RULES FOR EFFECTIVE QUALITY ASSURANCE</div>

      <div className='flex items-center justify-between md:justify-start lg:my-[64px] my-[40px]'>
        <span className='text-[#8786AB] text-[14px] md:mr-[40px]'>Tips and Advice</span>
        <span className='text-[#8786AB] text-[14px]'>July 18, 2023</span>
      </div>
      <div className="content text-[12px] md:text-[16px]">Molestiae magni dolore ut necessitatibus iste veritatis tempore. Ab velit vitae qui ipsa non eos. Et dolorem dolores occaecati illum fugiat consequatur dolorem enim ducimus. Porro accusantium et. Sit quod ea. Rem accusantium rerum architecto deserunt quos.</div>
    </div>


</div>


    {/* You may also like */}
    <div className='md:max-w-[90%] md:mx-auto'>
    <div className='text-[40px] font-bold mt-[109px] mb-[40px] lg:mt-[165px] lg:mb-[68px]'>You may also like</div>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-[24px] lg:gap-[32px]">
          {blogs.map((blog, index) => (
            <PostCard key={index} item={blog} />
          ))}
        </div>
    </div>

    </div>
  );
}