import {
  ContactUs,
  HomeAbout,
  HomeTeam,
  OurProducats,
  ServiceCard,
  Testimonials,
  WhyChooseUs,
  WhyPeopleChooseUs,
  Working,
} from "@/components";
import { CheckOutLatestPosts } from "@/components/elements/home/check-out-latest-posts";
import { BaseModel } from "@/core";
import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
export default function Home() {
  const { width } = useWindowSize();
  const [industries, setIndustries] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    getIndustries();
    getServices();
  }, []);

  const getServices = async () => {
    try {
      const payload = {
        offset: 1,
      };
      const { results } = await BaseModel.getServices(payload);
      if (results.length > 0) {
        setServices(results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getIndustries = async () => {
    try {
      const paylaod = {
        offset: 1,
      };
      const { results } = await BaseModel.getIndustries(paylaod);
      if (results.length > 0) {
        setIndustries(results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className="index-page relative mt-[-88px] pt-[190px] pb-[100px]"
    >

<img src="images/home/header-background.svg" className="absolute w-full top-0 hidden md:block z-[-1]" />
      <img src="images/home/mobile-header-background.svg" className="absolute w-full top-0 block md:hidden z-[-1]" />

      <div className="px-[36px] lg:px-0 lg:max-w-[90%] lg:mx-auto">

        <HomeAbout />
        <HomeTeam className="mt-[88px] lg:mt-[375px]" />
        <OurProducats className="mt-[88px] lg:mt-[105px]" />
       <WhyChooseUs className="mt-[98px] lg:mt-[200px]" />
       <ServiceCard className="mt-[159px] lg:mt-[100px]" services={services} />
       <Working className="mt-[85px] lg:mt-[213px]" industries={industries} />

      </div>
        
     <div className="w-full h-full" 
     style={{
      background:width < 990 ? "" :"url(images/home/center-background.svg) no-repeat",
      backgroundSize:'cover'
     }}
     >
       <div className="p-[36px] lg:px-0 lg:max-w-[90%] lg:mx-auto">
       <WhyPeopleChooseUs className="mt-[88px] lg:mt-[105px]" />

        <CheckOutLatestPosts className="mt-[88px] lg:mt-[105px]" />

        <Testimonials className="mt-[153px]" />

        <ContactUs className="mt-[112px] lg:mt-[210px]" />
       </div>
     </div>
    </div>
  );
}
