import { CardSlider, PostCard } from "@/components/core";
import clsxm from "@/lib/clsxm";
import { useAppDispatch, useAppSelector } from "@/store";
import { fetchBlogsList } from "@/store/blog/hooks";
import { IBlog } from "@/store/blog/reducer";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CheckOutLatestPosts: React.FC<{ className?: string }> = ({
  className,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { total, loading, blogs } = useAppSelector((state) => state.blogs);
  useEffect(() => {
    dispatch(fetchBlogsList({ offset: 1 }));
  }, []);
  return (
    <div className={className}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[68px]"
        )}
      >
        Check out latest posts
      </div>
      <div className="hidden lg:flex flex-col items-center">
        {blogs.length > 0 && <div className="grid grid-cols-3 gap-[32px] w-full">
          {[...blogs].splice(0,3).map((blog, index) => <PostCard key={index} item={blog} />)}
        </div>}
        <button
          className="text-[#000] mt-[40px] text-[16px] bg-[#FFB8D0] w-[178px] h-[68px] rounded-[9px] uppercase"
          onClick={() => {
            navigate("/blog")
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          Find More
        </button>
      </div>
      <div className="lg:hidden block">
        {blogs.length > 0 && (
          <CardSlider>
            {blogs.map((blog, index) => (
              <PostCard key={index} item={blog} />
            ))}
          </CardSlider>
        )}
      </div>
    </div>
  );
};
