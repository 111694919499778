import clsxm from "@/lib/clsxm";
import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

interface Props {
  className?: string;
  children: React.ReactNode;
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  arrows?: boolean;
  draggable?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  variableWidth?: boolean;
  adaptiveHeight?: boolean;
}

export const CarouselSlider: React.FC<Props> = (props) => {
  const {
    className,
    children,
    dots = true,
    infinite = true,
    speed = 500,
    arrows = false,
    draggable = false,
    slidesToShow = 1,
    slidesToScroll = 1,
    variableWidth = false,
    adaptiveHeight = true,
  } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const settings: Settings = {
    dots: dots,
    infinite: infinite,
    speed: speed,
    arrows: arrows,
    draggable: draggable,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    variableWidth: variableWidth,
    adaptiveHeight: adaptiveHeight,
    customPaging: (i: number) => {
      return (
        <div className="mt-[32px]">
          <div
            className={clsxm(
              "dot w-[16px] h-[16px] rounded-full cursor-pointer",
              i === currentSlideIndex ? "bg-[#FFB8D0]" : "bg-[#D0EDFD]"
            )}
          ></div>
        </div>
      );
    },
  };

  function SliderNextButton(props: any) {
    const { onClick } = props;
    return (
      <button className="card-slider-next" onClick={onClick}>
        <img src="images/icons/arrow-next.svg" className="w-[50px] h-[50px]" />
      </button>
    );
  }

  function SliderPreviousButton(props: any) {
    const { onClick } = props;
    return (
      <button className="card-slider-previous" onClick={onClick}>
        <img src="images/icons/arrow-prev.svg" className="w-[50px] h-[50px]" />
      </button>
    );
  }

  const sliderRef = useRef<Slider | null>(null);
  return (
      <Slider
        className={clsxm("w-full tag-slider", className)}
        ref={sliderRef}
        {...settings}
        beforeChange={(current, next) => setCurrentSlideIndex(next)}
        // nextArrow={<SliderNextButton />}
        // prevArrow={<SliderPreviousButton />}
      >
        {children}
      </Slider>
  );
};
