import { CardSlider, IndustrieCard } from "@/components/core";
import clsxm from "@/lib/clsxm";
import React from "react";
export const Working: React.FC<{ className?: string; industries: any[] }> = ({
  className,
  industries,
}) => {
  return (
    <div className={clsxm(className)}>
      <div
        className={clsxm(
          "lg:text-[40px] text-[32px] font-bold text-center uppercase mb-[68px]"
        )}
      >
        Industries we are working with
      </div>
      <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 rounded-[10px] lg:border lg:border-[#D0EDFD] px-[36px] lg:px-0 mt-[30px] lg:mt-0">
        {industries.length > 0 &&
          industries.map((industrie, index) => {
            return <IndustrieCard key={index} item={industrie} index={index} />;
          })}
      </div>

      <div className="lg:hidden block">
        {industries.length > 0 && (
          <CardSlider>
            {industries.map((industrie, index) => (
              <IndustrieCard key={index} item={industrie} index={index} />
            ))}
          </CardSlider>
        )}
      </div>
    </div>
  );
};
